import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './BlogPage.css';
import intro from './assets/medium/1_intro.png';
import fin from './assets/medium/2_finance.jpeg';
import heart from './assets/medium/3_heart.png';

const BlogPage = () => {
  const blogs = [
    {
      title: 'Demystifying Artificial Intelligence',
      description: 'A Beginner\'s Guide for Medical Professionals',
      imgSrc: intro,
      link: 'https://medium.com/@aiforhealthcare/demystifying-artificial-intelligence-a-beginners-guide-for-medical-professionals-103ff446f48a'
    },
    {
      title: 'Automation in Healthcare',
      description: 'Transforming the Financial Landscape of the Medical Industry',
      imgSrc: fin,
      link: 'https://medium.com/@aiforhealthcare/automation-in-healthcare-transforming-the-financial-landscape-of-the-medical-industry-2212d404984a'
    },
    {
      title: 'DIY Mini-Project: Predicting Heart Disease',
      description: 'Using Logistic Regression for Risk Prediction',
      imgSrc: heart,
      link: 'https://medium.com/@aiforhealthcare/diy-mini-project-predicting-heart-disease-risk-using-logistic-regression-a27a4f6c7d03'
    }
  ];

  return (
    <div className="blog-page">
      <h2 className="blogs-heading">Blogs</h2>
      <div className="card-container">
        {blogs.map((blog, index) => (
          <Card key={index} className="blog-card">
            <Card.Img variant="top" src={blog.imgSrc} alt={`Blog ${index + 1}`} />
            <Card.Body>
              <Card.Title>{blog.title}</Card.Title>
              <Card.Text>{blog.description}</Card.Text>
              <Button variant="primary" href={blog.link} target="_blank" rel="noopener noreferrer">
                Read More
              </Button>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
