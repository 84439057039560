import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './LandingPage.css'; 
import intro from './assets/intro.png';
import ehr from './assets/icons/electronic-healthcare.png';
import suggest from './assets/icons/doctor.png';
import onlinedoctor from './assets/icons/online-doctor.png';
import patientmonitor from './assets/icons/patient-monitor.png';
import planning from './assets/icons/planning.png';
import schedule from './assets/icons/schedule.png';
import teamwork from './assets/icons/team-work.png';
import workflow from './assets/icons/workflow.png';

import { ReactComponent as EHRIcon } from './assets/icons/EHR.svg';

import lottie from 'lottie-web'; // Import Lottie Web
import animationData from './assets/animations/hospital.json'; // Import your Lottie JSON animation
import animationDataDoc from './assets/animations/doctor.json';

const LandingPage = () => {
  useEffect(() => {
    const animationContainer = document.querySelector('#lottie-animation');
    const animationContainerDoc = document.querySelector('#lottie-animation-doc');

    // Load the Lottie animation
    const animation = lottie.loadAnimation({
      container: animationContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData, // Replace with your animation data
    });

    const animationDoc = lottie.loadAnimation({
      container: animationContainerDoc,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationDataDoc, // Replace with your animation data
    });

    return () => {
      animation.destroy(); // Clean up the animation on component unmount
      animationDoc.destroy();
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('card-visible');
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    const cards = document.querySelectorAll('.card-animated');
    cards.forEach((card) => {
      observer.observe(card);
    });

    return () => {
      cards.forEach((card) => {
        observer.unobserve(card);
      });
    };
  }, []);

  return (
    <div className="container-fluid landing-page"> {/* Bootstrap container for responsive layout */}
      {/* Bubbles */}
      <div className="bubble bubble-1"></div>
      <div className="bubble bubble-2"></div>
      <div className="bubble bubble-3"></div>
      <div className="bubble bubble-4"></div>
      <div className="bubble bubble-5"></div>

      {/* Introduction Section */}
      <section className="intro-section row align-items-center"> {/* Bootstrap row */}
        <div className="col-md-6 intro-content"> {/* Bootstrap column for text */}
          <h2 className='heading-font'>Welcome to Hsuite</h2>
          <p className="lead"> {/* Bootstrap's lead class for prominent text */}
            Transform the future of healthcare with our comprehensive solutions.
            Access a suite of tools that streamline consultations, unify medical records,
            and empower healthcare professionals for better patient outcomes!
          </p>
          <div className="intro-buttons">
            <Link className="btn btn-primary btn-lg" to="/signup"> {/* Bootstrap button */}
              Get Started
            </Link>
          </div>
        </div>
        <div className="col-md-6 intro-image text-center"> {/* Bootstrap column for image */}
          <img src={intro} alt="Introduction" className="img-fluid rounded" /> {/* Bootstrap responsive image */}
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section text-center">
        <h2>What We Offer</h2>
        <div className="row benefit-cards">
          <div className="col-md-6 mb-4">
            <div className="d-flex">
              <div className="benefit-image" id="lottie-animation">
                {/* Lottie animation will be rendered here */}
              </div>
              <div className="benefit-points" style={{ textAlign: 'left', marginLeft: '20px' }}>
                <h3 className="card-title">Hospitals</h3>
                <ul className="list-unstyled">
                  <li><img src={ehr} alt="Icon 1" className="benefit-icon" /> Tailor made EHRs exclusively for the hospitals</li>
                  <li><EHRIcon className="benefit-icon" /> Centralized Medical Record Management</li>
                  <li><img src={planning} alt="Icon 2" className="benefit-icon" /> Simplified Billing and Administration Tools</li>
                  <li><img src={workflow} alt="Icon 3" className="benefit-icon" /> Workflow Optimization Systems</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="d-flex">
              <div className="benefit-image" id="lottie-animation-doc">
                {/* Lottie animation will be rendered here */}
              </div>
              <div className="benefit-points" style={{ textAlign: 'left', marginLeft: '20px' }}>
                <h3 className="card-title">Doctors</h3>
                <ul className="list-unstyled">
                <li><img src={patientmonitor} alt="Icon 1" className="benefit-icon" /> Advanced Patient Monitoring Tools</li>
                <li><img src={teamwork} alt="Icon 2" className="benefit-icon" /> Case Management and Collaboration Platforms</li>
                <li><img src={schedule} alt="Icon 3" className="benefit-icon" /> Seamless Scheduling and Follow-up Systems</li>
                <li><img src={onlinedoctor} alt="Icon 4" className="benefit-icon" /> Resources for Remote Consultations</li>
                <li><img src={suggest} alt="Icon 5" className="benefit-icon" /> EHRs tailored with input from doctors to suit their needs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Vision and Mission Section */}
      <section className="vision-mission-section">
        <h2>Our Vision and Mission</h2>
        <div className="vision-mission-cards">
          <div className="vision-mission-card card-animated">
            <h3>Our Vision</h3>
            <p>
            To revolutionize healthcare by delivering highly customizable, hospital-specific Electronic Health Records (EHRs) that empower medical institutions to seamlessly digitize their operations, enhance patient outcomes, and adapt to their unique needs and aspirations.
            </p>
          </div>
          <div className="vision-mission-card card-animated">
            <h3>Our Mission</h3>
            <p>
            Our mission is to bridge the gap between healthcare and technology by designing EHR systems tailored to each hospital’s requirements. We aim to simplify digital transformation for medical institutions, enabling them to provide exceptional care while streamlining their workflows and aligning with their operational goals.
            </p>
          </div>
        </div>
      </section>

      {/* Contact Us Section */}
      <section className="contact-us-section text-center">
        <h2>Contact Us</h2>
        <p>If you have any questions, feel free to reach out!</p>
        <Link className="btn btn-primary" to="/contact">Contact Us</Link>
      </section>
    </div>
  );
};

export default LandingPage;

