// Footer.js
import React from 'react';
import './App.css';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <p>&copy; 2025 H-Suite. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
